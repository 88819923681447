.questionWrapper
  display: flex
  flex-direction: row
  align-items: center
  margin-bottom: 10px
  position: relative
  width: 100%

  form
    width: 100%

  input
    padding-left: 55px
    padding-right: 55px
    border-radius: 45px
    outline: none
    width: 100%
    font-size: 15px
    font-family: "Noto Sans"
    color: rgb(48, 49, 51)
    line-height: 1.5
    text-align: left
    border-style: solid
    border-width: 1px
    border-color: rgb(173, 173, 173)
    background-color: rgb(255, 255, 255)
    height: 50px
    z-index: 4

    @media (min-width: 600px)
      height: 60px
     
    &::placeholder
      font-size: 16px
      font-family: "Noto Sans"
      color: rgb(173, 173, 173)
      font-style: italic
      line-height: 1.5
      text-align: left
 
.inputStarted
  border-style: solid
  border-width: 1px
  border-color: rgb(173, 173, 173)
  animation-name: blinking
  animation-duration: 1s
  animation-iteration-count: 100

@keyframes blinking 
  50% 
    border-style: solid
    border-color: green
    border-width: 1px

.inputStopped
  border-style: solid
  border-width: 1px
  border-color: rgb(173, 173, 173)

.micBtn
  position: absolute
  right: 15px
  min-height: 30px
  min-width: 25px
  outline: none
  background-color: transparent
  border-radius: 50%
  cursor: pointer
  border: 1px solid transparent
  font-size: 20px
  font-family: "Font Awesome 6 Pro"
  color: rgb(100, 100, 100)

  img
    height: 19px
    font-size: 20px
    font-family: "Font Awesome 6 Pro"
    color: rgb(100, 100, 100)
    
    @media (min-width: 600px)
      height: auto

.xBtn
  position: absolute
  right: 15px
  min-height: 30px
  min-width: 25px
  outline: none
  background-color: transparent
  border-radius: 50%
  cursor: pointer
  border: 1px solid transparent
  font-size: 20px
  font-family: "Font Awesome 6 Pro"
  color: rgb(163, 164, 167)

  svg
    height: 18px

    @media (min-width: 600px)
      height: 1em

.magGlass
  position: absolute
  left: 20px
  font-size: 20px
  font-family: "Font Awesome 6 Pro"
  color: rgb(173, 173, 173)
  text-align: left

.hide
  display: none

.show
  display: block



